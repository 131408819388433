import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class NephrologistNurseAction extends Component {
      state = { fileData: '', results: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', waistHeightRatio: '', nurseNotes: '', patientDiagnosis: [], diagnosisText: [], vitals: [], notes: [], dialysisNotes:[], referralLetters: [], writebackLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: 0, msg: '', error: '', companyId: 0, patientId: 0
      , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', pregTest: '' , urea: '', postUrea: '',  creatinine: '', numericResults: [], numericResultDate: date1, list: [], dates: [], dialysisUnit: ''}
    componentDidMount() {

    }
    openNurseAction(visitId) {
        this.setState({ fileData: '', results: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', waistHeightRatio: '', nurseNotes: '', patientDiagnosis: [], diagnosisText: [], vitals: [], notes: [], dialysisNotes: [], referralLetters: [], writebackLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: visitId, msg: '', error: '' , companyId: 0, patientId: 0
        , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', pregTest: '', urea:'', postUrea: '',  creatinine: '',  dryWeight: '',  preDialysisWeight: '',  postDialysisWeight: '',  frequency: '',  ultraFiltrationGoal: '',  interdialyticWeightGain: '', dialysisUnit: ''})
        axios.get(localStorage.getItem('host') + '/patientmanagement/nurse/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                res.data.height = res.data.height === 0?"":res.data.height
                this.setState({ companyId: res.data.companyId, patientId: res.data.patientId, results: res.data.results, fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, patientDiagnosis: res.data.patientDiagnosis, diagnosisText: res.data.diagnosisText, vitals: res.data.vitals, notes: res.data.notes, dialysisNotes: res.data.dialysisNotes, referralLetters: res.data.referralLetters, writebackLetters: res.data.writebackLetters, oldPatient: res.data.oldPatient, managementData: res.data.managementData, historyVisits: res.data.historyVisits 
                    , ph: res.data.ph, ketones: res.data.ketones, sg: res.data.sg, proteins: res.data.proteins, leucocytes: res.data.leucocytes, urobilinogen: res.data.urobilinogen, blood: res.data.blood, nitrate: res.data.nitrate, glucose: res.data.glucose, otherUrineAnaysis: res.data.otherUrineAnaysis, pregTest: res.data.pregTest, urea: res.data.urea,  creatinine: res.data.creatinine, postUrea: res.data.postUrea, dryWeight: res.data.dryWeight,  preDialysisWeight: res.data.preDialysisWeight,  postDialysisWeight: res.data.postDialysisWeight,  frequency: res.data.frequency,  ultraFiltrationGoal: res.data.ultraFiltrationGoal,  interdialyticWeightGain: res.data.interdialyticWeightGain, dialysisUnit: res.data.dialysisUnit});
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        var data = { visitId: this.state.visitId, weight: this.state.weight, height: this.state.height, waist: this.state.waist, headCircumference: this.state.headCircumference, muac: this.state.muac, bmi: this.state.bmi, waistHeightRatio: this.state.waistHeightRatio, nurseNotes: this.state.nurseNotes, vitals: this.state.vitals, dryWeight: this.state.dryWeight,  preDialysisWeight: this.state.preDialysisWeight,  postDialysisWeight: this.state.postDialysisWeight,  frequency: this.state.frequency,  ultraFiltrationGoal: this.state.ultraFiltrationGoal,  interdialyticWeightGain: this.state.interdialyticWeightGain
            , ph: this.state.ph, ketones: this.state.ketones, sg: this.state.sg, proteins: this.state.proteins, leucocytes: this.state.leucocytes, urobilinogen: this.state.urobilinogen, blood: this.state.blood, nitrate: this.state.nitrate, glucose: this.state.glucose, otherUrineAnaysis: this.state.otherUrineAnaysis, pregTest: this.state.pregTest, urea: this.state.urea, postUrea: this.state.postUrea, creatinine: this.state.creatinine, dialysisUnit: this.state.dialysisUnit }
        axios.post(localStorage.getItem('host') + '/patientmanagement/nursesave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                this.props.updateAfterSave(this.state.visitId, [])
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('nurseAction').style = 'display:none'
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 40) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                var waistHeightRatio = 0;
                value = value > 0 && value < 250 ? value : 0
                if (value > 40 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                if (value > 0 && this.state.waist > 0) {
                    waistHeightRatio = Number((this.state.waist / value).toFixed("2"))
                }
                
                this.setState({ height: value, bmi: bmi, waistHeightRatio: waistHeightRatio })
                break;
            default:
                waistHeightRatio = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    waistHeightRatio = Number((value / this.state.height).toFixed("2"))
                }
                this.setState({ waist: value, waistHeightRatio: waistHeightRatio })
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : '';
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 180 ? value : '';
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 50 ? value : '';
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 300 ? value : '';
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : '';
                break;
            default:
                list[index].spO2 = value > 0 && value < 101 ? value : '';
                break;

        }
        this.setState({ vitals: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature:'', pulse: '', glucometer:'', systolic: '', diastolic:'', spO2: '' });
        this.setState({ vitals: list })
    }
    showNurseHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/nursehistory/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else if (fileType === "DIALYSISNOTES") {
                        this.setState({ dialysisNotes: res.data, msg: 'Successfully uploaded Dialysis Notes' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else if (fileType === "DIALYSISNOTES") {
                    this.setState({ dialysisNotes: res.data, msg: 'Successfully deleted Dialysis Notes' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: '' });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/getfile/' + id + '/' + fileExtension, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fileData: res.data });
                document.getElementById('showFileNurseAction1').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction1').style = 'display:none'
    }
    showOldNurse(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                //   this.setState({ htmlText: res.data, popupName: 'Nurse History'});
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    viewResults() {
        document.getElementById('resultPopup1').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup1').style = 'display:none'
    }
    setNumericResult(index, name, value) {
        let list = this.state.numericResults.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "doubleValue":
                list[index].doubleValue = value
                list[index].intValue = null
                list[index].stringValue = null
                break;
            case "intValue":
                list[index].doubleValue = null
                list[index].intValue = value
                list[index].stringValue = null
                break;
            default:
                list[index].doubleValue = null
                list[index].intValue = null
                list[index].stringValue = value
                break;
        }
        this.setState({ numericResults: list });
    }
    addNumericResultTable() {
        let list = this.state.numericResults.slice();
        list.push({ id: 0, name: '', doubleValue: '', intValue: '', stringValue: '' });
        this.setState({ numericResults: list });
    }

    numericResultPopup() {
        document.getElementById('numericresultPopup').style = 'display:none'
    }
    openNumericResultPopup() {
        document.getElementById('numericresultPopup').style = 'display:block'
        var list = []
        list.push({ id: 0, name: 'WBC', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'RBC', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'Hb', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'MCV', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'Plts', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'Na+', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'K+', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'Cl-', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'HCO3-', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'Urea', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'Creatinine', doubleValue: '', intValue: '', stringValue: '' });
        list.push({ id: 0, name: 'GFR', doubleValue: '', intValue: '', stringValue: '' });
        this.setState({numericResults: list, numericResultDate: date1})
    }
    saveNumericResult() {
        this.props.setLoader(true)
        var data = { visitId: this.state.visitId, numericResultDate: this.state.numericResultDate, numericResults: this.state.numericResults, fullname: this.state.fullname, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/patientmanagement/numericresultsave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                this.numericResultPopup()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )

    }
    openViewNumericResultPopup()
     {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/getnumericresult/' + this.state.patientId+"/"+this.state.companyId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ dates: res.data.dates, list: res.data.list });
                document.getElementById('numericResultViewPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )

        
     }
     closeNumericResultViewPopup() {
        document.getElementById('numericResultViewPopup').style = 'display:none'
    }
    deleteNumericResult(id)
    {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/deletenumericresult/'+id+"/" +this.state.patientId+"/"+this.state.companyId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ dates: res.data.dates, list: res.data.list });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    deleteRow(index)
    {
        let list = this.state.vitals.slice();
        list.splice(index, 1); 
        this.setState({ vitals: list });
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="nurseAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info">Nurse Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="resultPopup1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="numericresultPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Numeric Result Action</div>
                        <div className="card-body" >
                         <div className="row">
                           <div className="col"> <input type="text" className="form-control" value={this.state.numericResultDate} onChange={e => { this.setState({ numericResultDate: e.target.value }) }} /> 
                             <MyDatePicker  value={this.state.numericResultDate} maxDate={ date1} onChange={(d)=> {  this.setState({numericResultDate: d}); }} /></div>    
                        </div>   
                        <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Name</th>
                                                <th>Int Value</th>
                                                <th>Double Value</th>
                                                <th>String Value</th>
                                            </tr>
                                            <tbody>
                                                {this.state.numericResults.map((item, index) =>(
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setNumericResult(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            <option value="WBC">WBC</option>
                                                            <option value="RBC">RBC</option>
                                                            <option value="Hb">Hb</option>
                                                            <option value="MCV">MCV</option>
                                                            <option value="Plts">Plts</option>

                                                            <option value="U&E">U&E</option>
                                                            <option value="Na+">Na+</option>
                                                            <option value="K+">K+</option>
                                                            <option value="Cl-">Cl-</option>
                                                            <option value="HCO3-">HCO3-</option>
                                                            <option value="Urea">Urea</option>
                                                            <option value="Creatinine">Creatinine</option>
                                                            <option value="GFR">GFR</option>

                                                            <option value="Calcium">Calcium</option>
                                                            <option value="Magnesium">Magnesium</option>
                                                            <option value="Phospate">Phospate</option>
                                                            <option value="Para hormone">Para hormone</option>

                                                            <option value="T. Colesterol">T. Colesterol</option>
                                                            <option value="LDL/HDL">LDL/HDL</option>
                                                            <option value="Alb">Alb</option>

                                                            <option value="T. Bil">T. Bil</option>
                                                            <option value="D. Bil">D. Bil</option>
                                                            <option value="AST/ALT">AST/ALT</option>
                                                            <option value="ALP/GGT">ALP/GGT</option>

                                                            <option value="HIV">HIV</option>
                                                            <option value="HepBsAg">HepBsAg</option>
                                                            <option value="PSA">PSA</option>
                                                            <option value="ESR">ESR</option>
                                                            <option value="LDH">LDH</option>
                                                        </select></td>
                                                        <td><input type="text" className="form-control" value={item.intValue} onChange={e => { this.setNumericResult(index, 'intValue', e.target.value); }}  /></td>
                                                        <td><input type="text" className="form-control" value={item.doubleValue} onChange={e => { this.setNumericResult(index, 'doubleValue', e.target.value); }}  /></td>
                                                        <td><input type="text" className="form-control" value={item.stringValue} onChange={e => { this.setNumericResult(index, 'stringValue', e.target.value); }}  /></td>
                                                       </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addNumericResultTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                            <div className="row">
                            <div className="col" align="center"><button onClick={() => this.saveNumericResult()} className="btn btn-primary">Save</button></div>
                                <div className="col" align="center" ><button onClick={() => this.numericResultPopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="numericResultViewPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Numeric Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th></th>
                                    {this.state.dates.map((item, index) => (
                                    <th key={index}>{item}</th>
                                    ))}
                                </tr>
                                <tbody>
                                    {this.state.list.map((item, index) => (
                                        <tr key={index}>
                                           <td>{item.name}</td>
                                           {item.value.map((item1, index1) => (
                                            <td key={index1}>{item1.name} | <span className="link-red" onClick={() => this.deleteNumericResult(item1.id)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.closeNumericResultViewPopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="PDF File View" src={'data:application/pdf;base64,' + this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.showNurseHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                     
                        {this.state.results.length > 0 && (<div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    <li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Dialysis Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('DIALYSISNOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.dialysisNotes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Dialysis Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.dialysisNotes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'DIALYSISNOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Referral Letter</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('REFERRAL_LETTERS', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.referralLetters.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Referral Letters</div>
                            <div className="card-body">
                                <table >
                                    {this.state.referralLetters.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'REFERRAL_LETTERS')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Write Back Letter</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('WRITEBACK_LETTERS', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.referralLetters.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Referral Letters</div>
                            <div className="card-body">
                                <table >
                                    {this.state.writebackLetters.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'WRITEBACK_LETTERS')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                            </div>
                        </div>
                    <div className="row">
                        <div className="col"><button onClick={() => this.openNumericResultPopup()} className="btn btn-primary">Add Numeric Result</button></div>
                        <div className="col"><button onClick={() => this.openViewNumericResultPopup()} className="btn btn-primary">View Numeric Result</button></div>
                        <div className="col"><button onClick={() => this.props.taskAction(true)} className="btn btn-primary">Tasks</button></div>
                    </div>
                        <div id="110"></div>
                        <br />
                        <div className="card  card-blue">
                            <div className="card-header">
                                Nurse Details
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"> <div className="input-group"><span className="input-group-text">Weight (Kg)</span>
                                        <div><input type="text" className="form-control" value={this.state.weight} onChange={e => this.setNurseDetails("weight", e.target.value)} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Height (cm)</span>
                                        <div ><input type="text" className="form-control" value={this.state.height} onChange={e => this.setNurseDetails("height", e.target.value)} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">BMI</span>
                                        <div><input type="text" readonly="readonly" className="form-control" value={this.state.bmi} /></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Preg Test</span>
                                        <div ><select className="form-control" value={this.state.pregTest} onChange={e => this.setState({ pregTest: e.target.value })} >
                                            <option value="">Choose</option>
                                            <option value="Positive">Positive</option>
                                            <option value="Negative">Negative</option>
                                            <option value="Indeterminate">Indeterminate</option>
                                            </select></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Pre Urea (mmol/L)</span>
                                        <div ><input type="text" className="form-control" value={this.state.urea} onChange={e => this.setState({ urea: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Post Urea (mmol/L)</span>
                                        <div ><input type="text" className="form-control" value={this.state.postUrea} onChange={e => this.setState({ postUrea: e.target.value })} /></div>
                                    </div></div>
                                    </div>
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Creatinine</span>
                                        <div ><input type="text" className="form-control" value={this.state.creatinine} onChange={e => this.setState({ creatinine: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Dialysis Unit</span>
                                        <div ><input type="text" className="form-control" value={this.state.dialysisUnit} onChange={e => this.setState({ dialysisUnit: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Dry Weight</span>
                                        <div ><input type="text" className="form-control" value={this.state.dryWeight} onChange={e => this.setState({ dryWeight: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Pre Dialysis Weight</span>
                                        <div ><input type="text" className="form-control" value={this.state.preDialysisWeight} onChange={e => this.setState({ preDialysisWeight: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Post Dialysis Weight</span>
                                        <div ><input type="text" className="form-control" value={this.state.postDialysisWeight} onChange={e => this.setState({ postDialysisWeight: e.target.value })} /></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Frequency</span>
                                        <div ><input type="text" className="form-control" value={this.state.frequency} onChange={e => this.setState({ frequency: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Ultra Filtration Goal</span>
                                        <div ><input type="text" className="form-control" value={this.state.ultraFiltrationGoal} onChange={e => this.setState({ ultraFiltrationGoal: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Interdialytic Weight Gain</span>
                                        <div ><input type="text" className="form-control" value={this.state.interdialyticWeightGain} onChange={e => this.setState({ interdialyticWeightGain: e.target.value })} /></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Notes</span><textarea className="form-control" value={this.state.nurseNotes} onChange={e => this.setState({ nurseNotes: e.target.value })}></textarea></div></div>
                                </div>
                            </div>
                        </div>
                        <div className="card  card-blue">
                            <div className="card-header">
                                Vitals
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col" align="right"><input type="button" onClick={() => this.addVital()} value="Insert Line" className="btn btn-success" /></div>

                                </div>
                                <br />
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>Systolic</th>
                                        <th>Diastolic</th>
                                        <th>SpO2 (%)</th>
                                        <th>Action</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitals.map((item, index) => (
                                            <tr key={index}>
                                                <td><input type="text" className="form-control" value={item.vitalTime} onChange={e => { this.setVitalValue(index, 'vitalTime', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.temperature} onChange={e => { this.setVitalValue(index, 'temperature', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.pulse} onChange={e => { this.setVitalValue(index, 'pulse', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.glucometer} onChange={e => { this.setVitalValue(index, 'glucometer', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.systolic} onChange={e => { this.setVitalValue(index, 'systolic', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.diastolic} onChange={e => { this.setVitalValue(index, 'diastolic', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.spO2} onChange={e => { this.setVitalValue(index, 'spO2', e.target.value); }} /></td>
                                                <td ><span className="link-red" onClick={() => this.deleteRow(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.state.managementData && (<div >
                            <div className="card  card-blue">
                                <div className="card-header">Management</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span >{this.state.managementData}</span></div>
                                    </div>
                                </div></div>
                        </div>) }
                        {this.state.diagnosisText !== "" && (<div >
                            <div className="card  card-blue">
                                <div className="card-header">Diagnosis Text</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span >{this.state.diagnosisText}</span></div>
                                    </div>
                                </div></div>
                        </div>)}
                        {this.state.patientDiagnosis.length > 0 && (<div >
                            <div className="card  card-blue">
                                <div className="card-header">Diagnosis ICD 10</div>
                                <div className="card-body">
                                <table className="table">
                                        <tr>
                                            <th>Code</th>
                                            <th>Description</th>
                                        </tr>
                                        {this.state.patientDiagnosis.map((item, index) =>  (item.activeStatus === 1 &&(
                                            <tr key={index}>
                                            <td>{item.code}</td>
                                                <td>{item.description}</td>
                                             </tr>)))}
                                    </table>
                                </div></div>
                        </div>)}
                    </div>
                </div>
                <div className="row">
                    <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                    <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      